import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { RENTAL_PRICE_BASIS_TRANSLATION_KEYS } from '@/constants/rentalPrice';
import useFormatMessage from '@/hooks/useFormatMessage';
import useFormatPrice from '@/hooks/useFormatPrice';

const Price = ({ price, priceBasis }) => {
  const formatMessage = useFormatMessage();
  const formatPrice = useFormatPrice();

  if (!price) {
    return (
      <Typography component="div" color="primary.main" variant="h5Redesign">
        {formatMessage('rental_price_placeholder')}
      </Typography>
    );
  }

  return (
    <div>
      <Typography component="span" color="primary.main" variant="h5Redesign">
        {formatMessage('rental_price_from', {
          price: formatPrice(price),
        })}
      </Typography>
      <Typography
        component="span"
        typography="body1Redesign"
        color="primary.main">
        {` / ${formatMessage(
          priceBasis
            ? RENTAL_PRICE_BASIS_TRANSLATION_KEYS[priceBasis]
            : 'price_tile_basis_day'
        )}*`}
      </Typography>
      <Typography
        component="span"
        color="text.disabled"
        variant="captionRedesign">{` ${formatMessage(
        'product_details_page_price_net'
      )}`}</Typography>
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  priceBasis: PropTypes.string,
};

export default Price;
