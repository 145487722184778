export const RENTAL_PRICE_BASIS = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  UNIT: 'unit',
};

export const RENTAL_PRICE_BASIS_TRANSLATION_KEYS = {
  [RENTAL_PRICE_BASIS.HOUR]: 'rental_price_basis_hour',
  [RENTAL_PRICE_BASIS.DAY]: 'rental_price_basis_day',
  [RENTAL_PRICE_BASIS.WEEK]: 'rental_price_basis_week',
  [RENTAL_PRICE_BASIS.MONTH]: 'rental_price_basis_month',
};
