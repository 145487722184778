import { CircularProgress, Stack } from '@mui/material';
import NextImage from 'next/image';
import { useState } from 'react';

const ImageWithLoader = ({ loaderSize = 80, ...props }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
          justifyContent="center"
          alignItems="center">
          <CircularProgress size={loaderSize} thickness={5} color="primary" />
        </Stack>
      )}
      <NextImage {...props} onLoadingComplete={() => setLoading(false)} />
    </>
  );
};

export default ImageWithLoader;
