import { useIntl } from 'react-intl';

function useFormatPrice(omitZeroDecimals = true) {
  const { formatNumber } = useIntl();

  const formatPrice = price => {
    const numericValue = typeof price === 'number' ? price : Number(price);

    if (isNaN(numericValue)) {
      return null;
    }

    const hasFractionalPart = numericValue % 1 !== 0;

    return formatNumber(numericValue, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...(omitZeroDecimals && {
        minimumFractionDigits: hasFractionalPart ? 2 : 0,
        maximumFractionDigits: hasFractionalPart ? 2 : 0,
      }),
    });
  };

  return formatPrice;
}

export default useFormatPrice;
