import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Stack, Typography } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import AddToBasketConfirmationDialog from '@/components/AddToBasketConfirmationDialog/AddToBasketConfirmationDialog';
import HourCounter from '@/components/ui/HourCounter/HourCounter';
import Input from '@/components/ui/Input/Input';
import InputCalendar from '@/components/ui/InputCalendar/InputCalendar';
import { HOURS } from '@/helpers/constants';
import { getHoursResetValue } from '@/helpers/directRequest';
import { useBasketContext } from '@/hooks/useBasketContext';
import useFormatMessage from '@/hooks/useFormatMessage';

import { productDetailsDataPropTypes } from '../propTypes';
import {
  CalendarsWrapper,
  Chip,
  InputCounter,
  SubmitButton,
  Wrapper,
} from './RequestForm.style';

const validationSchema = Yup.object({
  rentalObject: Yup.object({
    name: Yup.string()
      .trim()
      .required(),
    productReferenceId: Yup.number().nullable(),
    salesforceId: Yup.string().nullable(),
    productGroupName: Yup.string().nullable(),
    productGroupId: Yup.number().nullable(),
    automatedOffer: Yup.bool(),
    minLeadTime: Yup.number().nullable(),
    rentalPriceBasis: Yup.string().nullable(),
    rentalPrice: Yup.number().nullable(),
    invoiceBasis: Yup.string().nullable(),
  }),
  count: Yup.number().required(),
  dateFrom: Yup.date().required(),
  dateTo: Yup.date().required(),
  description: Yup.string().trim(),
  rentalHours: Yup.number().nullable(),
});

const defaultValues = {
  count: 1,
  dateFrom: null,
  dateTo: null,
  description: '',
  rentalHours: null,
};

const RequestForm = ({ data = {} }) => {
  const {
    name,
    product_reference_id: productReferenceId,
    product_group_id: productGroupId,
    product_group_name: productGroupName,
    salesforce_id: salesforceId,
    automated_offer: automatedOffer,
    min_lead_time: minLeadTime,
    rental_price_basis: rentalPriceBasis,
    rental_price: rentalPrice,
    invoice_basis: invoiceBasis,
  } = data;

  const rentalObject = {
    name,
    productReferenceId,
    productGroupName,
    productGroupId,
    salesforceId,
    automatedOffer,
    minLeadTime,
    rentalPriceBasis,
    rentalPrice,
    invoiceBasis,
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setFocus,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { ...defaultValues, rentalObject },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const formatMessage = useFormatMessage();
  const { addRequestToBasket, machineAlreadyInBasket } = useBasketContext();
  const [duplicatedMachineData, setDuplicatedMachineData] = useState(null);

  const router = useRouter();

  const rentalObjectInvoiceBasis = invoiceBasis;
  const dateFromWatched = watch('dateFrom');
  const dateToWatched = watch('dateTo');
  const showHourCounter =
    HOURS.includes(rentalObjectInvoiceBasis) &&
    isSameDay(dateFromWatched, dateToWatched);

  const handleDateFromChange = (value, onChange) => {
    onChange(value);
    setValue('dateTo', null, { shouldDirty: true });
    setFocus('dateTo');
  };

  const handleDateToChange = newDateTo => {
    if (isSameDay(newDateTo, dateToWatched)) return;
    if (dateFromWatched && HOURS.includes(rentalObjectInvoiceBasis)) {
      setValue('rentalHours', getHoursResetValue(dateFromWatched, newDateTo));
    }
  };

  const handleFormSubmit = async values => {
    addRequestToBasket(values);
    await router.push('/basket');
  };

  const handleDuplicatedMachine = callback => (values, event) => {
    if (machineAlreadyInBasket(values.rentalObject)) {
      setDuplicatedMachineData({
        machineName: values.rentalObject.name,
        values,
      });
    } else {
      callback(values, event);
    }
  };

  const handleAddDuplicatedMachine = () => {
    handleFormSubmit(duplicatedMachineData.values);
  };

  const handleDontAddDuplicatedMachine = async () => {
    await router.push('/basket');
  };

  return (
    <>
      <Wrapper elevation={0} sx={{ mt: 4 }}>
        <Stack
          component="form"
          p={2}
          noValidate
          onSubmit={handleSubmit(handleDuplicatedMachine(handleFormSubmit))}>
          <Stack direction="row" alignItems="center" spacing={0.5} mb={2}>
            <InfoIcon
              sx={{ color: theme => theme.palette.grayscale[400] }}
              fontSize="small"
            />
            <Typography
              color="text.secondary"
              variant="captionRedesign"
              component="p"
              sx={{ lineHeight: 1 }}>
              {formatMessage('product_details_page_form_info')}
            </Typography>
          </Stack>
          <CalendarsWrapper direction="row">
            <Controller
              name="dateFrom"
              control={control}
              render={({
                field: { ref, value, onChange, onBlur, name },
                fieldState: { error },
              }) => {
                return (
                  <InputCalendar
                    value={value}
                    label={formatMessage(
                      'product_details_page_form_start_date'
                    )}
                    onChange={e => handleDateFromChange(e, onChange)}
                    onBlur={onBlur}
                    tabIndex={0}
                    inputRef={ref}
                    name={name}
                    error={!!error}
                    helperText={
                      error
                        ? formatMessage('form_mandatoryInput', {
                            inputName: formatMessage(
                              'product_details_page_form_start_date'
                            ),
                          })
                        : ' '
                    }
                    required
                    fullWidth
                  />
                );
              }}
            />
            <Controller
              name="dateTo"
              control={control}
              render={({
                field: { onChange, onBlur, ref, value, name },
                fieldState: { error },
              }) => {
                return (
                  <InputCalendar
                    label={formatMessage('product_details_page_form_end_date')}
                    name={name}
                    value={value}
                    onChange={value => {
                      onChange(value);
                      handleDateToChange(value);
                    }}
                    onBlur={onBlur}
                    tabIndex={0}
                    inputRef={ref}
                    error={!!error}
                    helperText={
                      error
                        ? formatMessage('form_mandatoryInput', {
                            inputName: formatMessage(
                              'product_details_page_form_end_date'
                            ),
                          })
                        : ' '
                    }
                    required
                    disabledTo={dateFromWatched}
                    presented={dateFromWatched}
                    fullWidth
                  />
                );
              }}
            />
          </CalendarsWrapper>
          {showHourCounter && (
            <Controller
              name="rentalHours"
              control={control}
              render={({ field: { ref, value, onChange, onBlur, name } }) => {
                return (
                  <HourCounter
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    onHourChange={onChange}
                  />
                );
              }}
            />
          )}
          <Stack direction="row" spacing={1} mb={2} alignItems="center">
            <Typography
              color="text.secondary"
              variant="captionRedesign"
              component="p"
              sx={{ lineHeight: 1 }}>
              {formatMessage('product_details_page_form_description_label')}
            </Typography>
            <Chip size="small" color="gray">
              {formatMessage('product_details_page_form_optional_chip')}
            </Chip>
          </Stack>
          <Box mb={2}>
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  fullWidth
                  multiline
                  rows={3}
                  autoComplete="off"
                  error={!!error}
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Controller
              name="count"
              control={control}
              render={({ field: { ref, value, onChange, onBlur, name } }) => {
                return (
                  <InputCounter
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    min={1}
                    name={name}
                    variant="large"
                  />
                );
              }}
            />
            <SubmitButton
              type="submit"
              fullWidth
              size="extraLarge"
              color="primary"
              startIcon={<ShoppingCartIcon fontSize="small" />}
              disabled={isSubmitting}>
              {formatMessage('product_details_page_form_submit_button')}
            </SubmitButton>
          </Stack>
        </Stack>
      </Wrapper>
      <AddToBasketConfirmationDialog
        open={!!duplicatedMachineData}
        onAddAgain={handleAddDuplicatedMachine}
        onDontAddAgain={handleDontAddDuplicatedMachine}
        machineName={duplicatedMachineData?.machineName}
      />
    </>
  );
};

RequestForm.propTypes = {
  data: productDetailsDataPropTypes,
};

export default RequestForm;
