import { inputBaseClasses, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import Button from '@/components/ui/Button/Button';
import ChipBase from '@/components/ui/Chip/Chip';
import InputCounterBase from '@/components/ui/InputCounter/InputCounter';
import Paper from '@/components/ui/Paper/Paper';

export const Wrapper = styled(Paper)(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.grayscale[100],
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  ...theme.typography.buttonSmallRedesign,
  textTransform: 'uppercase',
  width: 330,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const Chip = styled(ChipBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(8),
  lineHeight: 1,
  height: theme.typography.pxToRem(18),
}));

export const CalendarsWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

  '& > :last-child': {
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },

    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

export const InputCounter = styled(InputCounterBase)(({ theme }) => ({
  flex: 1,

  [theme.breakpoints.down('sm')]: {
    maxWidth: 166,
    width: '100%',
    alignSelf: 'center',
  },
}));
