import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const closeIconSizeDesktop = 40;
const closeIconSizeMobile = 30;
const closeIconMargin = 8;
const modalVerticalPaddingDesktop =
  (closeIconSizeDesktop + 2 * closeIconMargin) * 2;
const modalVerticalPaddingMobile =
  (closeIconSizeMobile + 2 * closeIconMargin) * 2;

export const ImageOuterWrapper = styled(Box, {
  shouldForwardProp: prop => !['active', 'next'].includes(prop),
})(({ theme, active, next }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  visibility: active ? 'initial' : 'hidden',
  opacity: active ? 1 : 0,
  transform: active ? 'translateX(0)' : `translateX(${next ? '20%' : '-20%'})`,
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  width: '80vw',
  aspectRatio: '4/3',
  maxHeight: `min(80vh, calc(100vh - ${modalVerticalPaddingDesktop}px))`,
  maxWidth: '1200px',
  borderRadius: theme.shape.borderRadiusLarge,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[10],

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    width: `calc(100vw - ${theme.spacing(2)})`,
    maxHeight: `calc(100vh - ${modalVerticalPaddingMobile}px)`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ImageWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const ArrowWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'prev',
})(({ prev, theme }) => ({
  width: '10vw',
  paddingLeft: theme.spacing(2),
  zIndex: 1,

  ...(!prev && {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingLeft: 0,
  }),

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Arrow = styled(Box, {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ theme, disabled }) => ({
  fontSize: 34,
  color: theme.palette.common.white,
  opacity: disabled ? 0.3 : 0.7,
  cursor: disabled ? 'default' : 'pointer',
  transition: 'opacity 0.3s ease',

  '&:hover': {
    opacity: disabled ? 0.3 : 1,
  },

  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  right: closeIconMargin,
  top: closeIconMargin,
  borderRadius: '50%',
  background: 'transparent !important',
  opacity: 1,
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.7,
  },

  svg: {
    color: theme.palette.common.white,
    width: closeIconSizeDesktop,
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      width: closeIconSizeMobile,
    },
  },
}));

export const SlidesThumbnails = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  paddingBottom: '1vh',
  lineHeight: 0,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'auto hidden',
  textAlign: 'center',
}));

export const SlidesThumbnail = styled(Box)(() => ({
  width: '7vh',
  maxWidth: 80,
  padding: '1vh 0.5vh',
  display: 'inline-block',
  boxSizing: 'content-box',
}));

export const SlidesThumbnailImageWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  backgroundColor: 'white',
  height: '7vh',
  maxHeight: 80,
  borderRadius: theme.shape.borderRadius,
  '&:hover img': {
    opacity: 0.5,
  },
  img: {
    opacity: active ? 0.5 : 1,
    transition: 'opacity 0.3s ease',
  },
}));
