import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Input from '@/components/ui/Input/Input';

const Select = ({ options, ...props }) => {
  return (
    <Input {...props} select fullWidth>
      {options.map(option => (
        <MenuItem
          data-cy={option.label}
          key={option.value}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Input>
  );
};

// Example of TextField being used as a select https://mui.com/components/text-fields/#select
// All the select props should be passed as SelectProps as mentioned here https://mui.com/api/text-field/#main-content
// Select API https://mui.com/api/select/
Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    })
  ),
};

export default Select;
