import {
  Chip as MuiChip,
  chipClasses,
  Table as MuiTable,
  TableCell as MuiTableCell,
  tableRowClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ButtonBase from '@/components/ui/Button/Button';

export const Table = styled(MuiTable, {
  shouldForwardProp: prop => prop !== 'hasProductSample',
})(({ hasProductSample }) => ({
  [`.${tableRowClasses.root}:nth-of-type(${
    hasProductSample ? 'odd' : 'even'
  })`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  border: 'none',
  color: theme.palette.text.secondary,
  ...theme.typography.body1Redesign,
}));

export const DataTableCell = styled(TableCell)(() => ({
  '&:last-child': {
    width: 172,
  },
}));

export const ViewPDFButton = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'uppercase',
  ...theme.typography.buttonMediumRedesign,
  minWidth: 215,
}));

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'uppercase',
  ...theme.typography.buttonSmallRedesign,
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
}));
