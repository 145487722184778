import PropTypes from 'prop-types';
import React from 'react';

import { ChipWrapper } from './Chip.style';

const Chip = ({
  children,
  color = 'blue',
  size = 'medium',
  uppercase = false,
  ...props
}) => {
  return (
    <ChipWrapper color={color} size={size} uppercase={uppercase} {...props}>
      {children}
    </ChipWrapper>
  );
};

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'gray', 'errorLight', 'success']),
  size: PropTypes.oneOf(['medium', 'small']),
  uppercase: PropTypes.bool,
};

export default Chip;
